// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/resource.module.scss";

export default function Resource({children}) {
    return (
        <div className={styles.resource}>
            {children}
        </div>
    );
}
