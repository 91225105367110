// extracted by mini-css-extract-plugin
export const sectionSpacing = "big-card-module--section-spacing--2EQSk";
export const rowSpacing = "big-card-module--row-spacing--28RTf";
export const rowSpacingSml = "big-card-module--row-spacing-sml--1vbDE";
export const bigCard = "big-card-module--bigCard--2EarO";
export const bcYellow = "big-card-module--bcYellow--1GJqr";
export const bcOrange = "big-card-module--bcOrange--10zGd";
export const bcTurquoise = "big-card-module--bcTurquoise--25Oou";
export const bcRed = "big-card-module--bcRed--2L90s";
export const bcGreen = "big-card-module--bcGreen--3joJc";
export const bcWhite = "big-card-module--bcWhite--1CtAA";