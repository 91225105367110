// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/big-card.module.scss";

export default function BigCard({children, color}) {
    switch (color) {
        case 'yellow':
            return (
                <div className={`container ${styles.bigCard} ${styles.bcYellow}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
        case 'orange':
            return (
                <div className={`container ${styles.bigCard} ${styles.bcOrange}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
        case 'turquoise':
            return (
                <div className={`container ${styles.bigCard} ${styles.bcTurquoise}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
        case 'red':
            return (
                <div className={`container ${styles.bigCard} ${styles.bcRed}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
        case 'green':
            return (
                <div className={`container ${styles.bigCard} ${styles.bcRed}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
        case 'white':
            return (
                <div className={`container ${styles.bigCard} ${styles.bcWhite}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
        default:
            return (
                <div className={`container ${styles.bigCard} ${styles.bcWhite}`}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            );
    }
}
