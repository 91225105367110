// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import BigCard from "../components/big-card";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import PdfDisplay from "../components/pdf-display";
import Resource from "../components/resource";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopYellow from "../components/separators/separator-top-yellow";

// import styles and assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import mineducImg from "../images/stickers-cuadrados-caja-herramientas.jpg";
import pdfDoc from "../images/revista_digital.pdf";
import * as styles from "./styles/cajas-stem.module.scss";

export default function Mineduc({data, pageContext}) {
    return (
<Layout>
    <div className={styles.intro}></div>
    <Section color={'white'}>
        <BigCard color="white">
            <div className={`d-flex flex-column col-sm-12 order-sm-last col-md-6 order-md-first align-self-center ${styles.introText}`}> 
                <h1>Kit de Herramientas STEM</h1>
                <h4>Kit de herramientas STEM: un kit realizado para el proyecto Aprende STEM desde Casa del Ministerio de Educación e Ingeniosas. Materiales para 12 actividades, que incluyen talleres de electrónica, autoconfianza, ciencia, entre otros.</h4>
                <h6>¡Sigue explorando las STEM junto a estas entretenidas actividades!</h6>
                <a href="https://www.mineduc.cl/">Link al Ministerio de Educación</a>
            </div>
            <div className="col-sm-12 order-sm-first col-md-6 order-md-last align-self-center">
                <img src={mineducImg} alt="kits interactivos"/>
            </div>
        </BigCard>
    <SeparatorTopGrey />
    </Section>
    <Section color="grey">
        <div className={`container ${styles.infographic}`}>
            <div className={`row ${styles.infoTitleRow}`}>
                <div className="col-sm-12 justify-content-center align-self-center">
                    <h1>Revista digital Aprende STEM desde casa</h1>
                    <h4>Las notas STEM más entretenidas, en un solo lugar. Revisa todo el contenido de manera virtual, aquí.</h4>
                </div>
            </div>
            <div className={`row ${styles.infoRow}`}>
                <div className="col-sm-12 justify-content-center align-self-center">
                    <PdfDisplay docFile={pdfDoc} display="wide"/>
                </div>
            </div>
        </div>
    <SeparatorTopYellow />
    </Section>
    <Section color={'yellow'}>
        <div className="container">
            <div className={`row ${styles.titleRow}`}>
                <div className="col-12">
                    <SectionHeader>
                        <h1>Videos</h1>
                        <h4>Actividades para explorar las STEM desde tu casa</h4>
                    </SectionHeader>
                </div>
            </div>
            <div className={`row ${styles.videoRow}`}>
                {data.allSanityPost.nodes.map((node) => (  
                    <div key={node.id} className="col-sm-12 col-md-6">
                        <Resource>
                            <div className="photo" key={node.mainImage.asset.id}>
                                <Image 
                                    {...node.mainImage}
                                    width={320}
                                    height={320}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                    alt="Imágen de taller Mineduc" />
                            </div>
                            <div className="box">
                                <h4>{node.title}</h4>
                                <div className="separator"></div>
                                <p>{node.excerpt}</p>
                                <Link to={`/caja-stem-mineduc/${node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                            </div>
                        </Resource>
                        {/* <VideoPost key={node.id} videoLink={node.video[0].url}>
                            <h4>{node.title}</h4>
                            <p>{node.description}</p>
                        </VideoPost> */}
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-12">
                    <Pagination pageContext={pageContext}></Pagination>
                </div>
            </div>
        </div>
        <SeparatorTopGrey />
    </Section>
</Layout>
    );
}

export const query = graphql`
query cajaStemMineducPageQuery ($skip: Int!, $limit: Int!) {
    allSanityPost (
    filter: {category: {title: {eq: "Cajas STEM"}}, tags: {elemMatch: {title: {eq: "Mineduc"}}}}
    sort: {order: DESC, fields: publishedAt},
    skip: $skip, 
    limit: $limit) {
        nodes {
            id
            title
            slug {
                current
            }
            excerpt
            mainImage {
                ...ImageWithPreview
            }
        }
    }
  }
`;