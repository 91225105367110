// extracted by mini-css-extract-plugin
export const sectionSpacing = "cajas-stem-module--section-spacing--sNcIl";
export const rowSpacing = "cajas-stem-module--row-spacing--195Lc";
export const rowSpacingSml = "cajas-stem-module--row-spacing-sml--9LJXA";
export const intro = "cajas-stem-module--intro--naRHc";
export const introText = "cajas-stem-module--introText--1PNgR";
export const titleRow = "cajas-stem-module--titleRow--3MnbM";
export const infographic = "cajas-stem-module--infographic--1R5es";
export const infoTitleRow = "cajas-stem-module--infoTitleRow--hYwMB";
export const infoRow = "cajas-stem-module--infoRow--3eNiO";
export const videoRow = "cajas-stem-module--videoRow--1mwLh";